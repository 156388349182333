import {mapActions, mapGetters} from 'vuex'
import { doQuery, doAction } from "@/api/core"
import util from '@/utils/cmyUtils.js'
import CmyPage from '@/components/cmy-page/cmy-page.vue'
import BbsPersonalArea from '@/components/bbs-personal-area/bbs-personal-area.vue'
import E from "wangeditor";

export default {
    name: "postDetail",
    inject:['toBottom'],
    data() {
        return {
            post_id: undefined,
            post: {},
            comments: [],
            comments_pagination: {
                page: 1,
                limit: 10,
                end: false
            },
            sort_type: [{
                text: '最早回复',
                id: '1',
            }, {
                text: '最晚回复',
                id: '0',
            }, {
                text: '最多点赞',
                id: '2',
            }, {
                text: '只看楼主',
                id: '3',
            }],
            sort_type_index: 0,

            comment_to_index: null,
            comment_to_index_content: '',
            comment_content: '',

            staff_id_select: undefined,
            personal_area_mode: undefined
        }
    },
    computed: {
        ...mapGetters([
            'winSize',
            'staffId',
            'myInfo'
        ])
    },
    components: {
        CmyPage,
        BbsPersonalArea
    },
    mounted() {
        if(this.$route.query && this.$route.query.post_id) {
            this.post_id = this.$route.query.post_id
            this.getPostDetail()
            this.queryComments()
        }
    },
    methods: {
        onMenuSelect({ item, key, selectedKeys }) {
            const that = this
            that.sort_type_index = key
            that.queryComments()
        },
        deletePost() {
            const that = this
            that.$confirm({
                centered: true,
                title: '提示',
                content: '是否确定删除该帖?',
                okText: '确定',
                okType: 'primary',
                cancelText: '取消',
                onOk() {
                    doAction({
                        action_code: 'LNFR90',
                        param_str2: that.post.post_id
                    }).then(res => {
                        if(res.errcode >= that.$consts.biz.OK ) {
                            if(res.data.errcode >= that.$consts.biz.OK) {
                                that.$message.success('操作成功')
                                that.$router.push({name: 'StudentBBS'})
                            }else {
                                that.$message.error(res.data.errmsg)
                            }

                        }else {
                            that.$message.error(res.errmsg)
                        }
                    })
                },
                onCancel() {

                }
            })
        },
        changeMode(mode) {
            const that = this
            if(mode == that.personal_area_mode) {
                return
            }else {
                that.personal_area_mode = mode
            }
        },
        doFollow() {
            const that = this
            doAction({
                action_code: 'LNFR40',
                param_str2: that.post.post_staff_id
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK ) {
                    if(res.data.errcode >= that.$consts.biz.OK) {
                        that.$message.success(that.post.is_followed == 1 ? '已取消关注' : '关注成功')
                        that.post.is_followed = that.post.is_followed == 1 ? 0 : 1
                    }else {
                        that.$message.error(res.data.errmsg)
                    }
                }else {
                    that.$message.error(res.errmsg)
                }
            })
        },
        resetFollowCallback(is_followed) {
            const that = this
            that.post.is_followed = is_followed
        },

        openBBSPersonalArea(staff_id) {
            const that = this
            that.staff_id_select = staff_id
            that.personal_area_mode = 'post'
        },
        closeBBSPersonalArea() {
            const that = this
            that.staff_id_select = undefined
            that.personal_area_mode = undefined
        },

        doComment(comment_to_index) {
            const that = this
            var data = {
                post_id: that.post_id,
                ref_comment_id: comment_to_index != null ? that.comments[comment_to_index].comment_id :'',
                content: comment_to_index != null ? util.clearEmoji(that.comment_to_index_content) : util.clearEmoji(that.comment_content),
                comment_staff_id: that.staffId,
            }
            doAction({
                action_code: 'LNFR20',
                param_str5: JSON.stringify(data)
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK && res.data.errcode >= that.$consts.biz.OK) {
                    that.sort_type_index = 1
                    that.post.cnt_comment = that.post.cnt_comment + 1
                    that.comment_content = ''
                    if(comment_to_index) {
                        that.comment_to_index = null
                        that.comment_to_index_content = ''
                    }
                    that.queryComments()
                }else {
                    that.$message.error(res.errmsg)
                }
            })
        },
        setCommentTo(index) {
            const that = this
            if(index == that.comment_to_index) {
                that.comment_to_index = null
            }else {
                that.comment_to_index_content = ''
                that.comment_to_index = index
            }


        },
        toReply() {
            const that = this
            that.toBottom()
        },
        doFavored() {
            const that = this
            doAction({
                action_code: 'LNFR50',
                param_str2: that.post.post_id
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK && res.data.errcode >= that.$consts.biz.OK) {
                    that.$message.success(that.post.is_favored == 1 ? '已取消收藏' : '收藏成功')
                    that.post.is_favored = that.post.is_favored == 1 ? 0 : 1
                }else {
                    that.$message.error(res.errmsg)
                }
            })
        },
        doPraise(comment_index = null) {
            const that = this
            doAction({
                action_code: 'LNFR30',
                param_str5: JSON.stringify({
                    post_id: that.post_id,
                    comment_id: comment_index != null ? that.comments[comment_index].comment_id : '',
                    praise_staff_id: that.staffId,
                })
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK && res.data.errcode >= that.$consts.biz.OK) {
                    if (comment_index == null) {
                        that.$message.success(that.post.is_praised == 1 ? '已取消点赞' : '点赞成功')
                        that.post.is_praised = that.post.is_praised == 1 ? 0 : 1
                    } else {
                        var bo_praise = that.comments[comment_index].is_praised == 0
                        var cnt_praise = that.comments[comment_index].cnt_praise

                        that.$message.success(!bo_praise ? '已取消点赞' : '点赞成功')

                        that.$set(that.comments[comment_index], 'is_praised', bo_praise ? 1 : 0)
                        that.$set(that.comments[comment_index], 'cnt_praise', bo_praise ? cnt_praise + 1 : cnt_praise - 1)
                    }
                }else {
                    that.$message.error(res.errmsg)
                }
            })
        },
        doAdopt(index) {
            const that = this
            that.$confirm({
                centered: true,
                title: '提示',
                content: '确定要采纳这条回复?',
                okText: '确定',
                okType: 'primary',
                cancelText: '取消',
                onOk() {
                    doAction({
                        action_code: 'LNFR60',
                        param_str1: that.comments[index].comment_id,
                    }).then(res => {
                        if(res.errcode >= that.$consts.biz.OK && res.data.errcode >= that.$consts.biz.OK) {
                            if(res.data.errcode >= that.$consts.biz.OK) {
                                that.$message.success('操作成功')
                                that.$set(that.comments[index], 'is_adopted', '1')
                                that.post.cnt_adopted = that.post.cnt_adopted + 1
                            }else {
                                that.$message.error(res.data.errmsg)
                            }

                        }else {
                            that.$message.error(res.errmsg)
                        }
                    })
                },
                onCancel() {

                }
            })
        },
        toStaffInfoDetail(comment_staff_id) {
            const that = this
            that.openBBSPersonalArea(comment_staff_id)
        },
        openDoc(url) {
            const that = this
            var a = document.createElement('a')
            a.href = url
            a.target = '_blank'
            a.id='exppub'
            document.body.appendChild(a)
            var alink = document.getElementById('exppub')
            alink.click()
            alink.parentNode.removeChild(a)
        },
        getPostDetail() {
            const that = this
            var hide = that.$message.loading('拼命加载中...',0)
            doQuery({
                query_code: 'LNFQ20',
                param_str1: that.post_id,
                page: 1,
                limit: 1
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK) {
                    that.post = res.data[0]
                }
            }).finally(() => {
                hide()
            })
        },
        queryComments(page = 1) {
            const that = this
            doQuery({
                query_code: 'LNFQ30',
                param_str1: that.post_id,
                param_str3: that.sort_type[that.sort_type_index].id,
                page: page,
                limit: that.comments_pagination.limit
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK && res.data) {
                    that.comments = res.data
                    that.comments_pagination.page = page
                    that.comments_pagination.end = res.data.length < that.comments_pagination.limit
                }
            })
        },

    }
}
